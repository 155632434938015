// import { SessionMessage } from '@camino-solutions/share/proto/all';
//

import { SessionTask } from '../session-state.model';
import { SessionMessage } from '@camino-solutions/share/proto/common/session';

export type SessionActionTypes =
  | 'disconnected'
  | 'connecting'
  | 'reConnecting'
  | 'connected' /*| 'addSessionMessage'*/;
//
// export class DisconnectedAction {
//   static readonly type: SessionActionTypes = 'disconnected';
// }
// export class ConnectingAction {
//   static readonly type: SessionActionTypes = 'connecting';
// }
// export class ReConnectingAction {
//   static readonly type: SessionActionTypes = 'reConnecting';
// }
// export class ConnectedAction {
//   static readonly type: SessionActionTypes = 'connected';
// }
// export class AddSessionMessageAction {
//   static readonly type: SessionActionTypes = 'addSessionMessage';
//
//   constructor(readonly sessionMessage: SessionMessage) {}
// }

// export class SessionTempSendRequestAction {
//   static readonly type = '[Session] temp action: send request';
//
//   constructor(readonly request: TestRequest) {}
// }

export class SessionConnectedAction {
  static readonly type = '[Session] connected to server stream';
}

export class SessionConnectingAction {
  static readonly type = '[Session] connecting to server stream';
}

export class SessionReConnectingAction {
  static readonly type = '[Session] re-connecting to server stream';
}

export class SessionDisconnectedAction {
  static readonly type = '[Session] disconnected to server stream';
}

export class SessionCreateTaskAction {
  static readonly type = '[Session] create task';

  constructor(readonly referenceId: string) {}
}

export class SessionSetTaskIdAction {
  static readonly type = '[Session] set task id';

  constructor(
    readonly referenceId: SessionTask['referenceId'],
    readonly taskId: NonNullable<SessionTask['taskId']>,
  ) {}
}

export class SessionErrorWhenGetTaskIdAction {
  static readonly type = '[Session] error when get task id';

  constructor(readonly referenceId: SessionTask['referenceId']) {}
}

export class SessionAddMessageAction {
  static readonly type = '[Session] add message';

  constructor(readonly sessionMessage: SessionMessage) {}
}

/**
 * Kivesszi az osszes task-t ami vegzett
 */
export class SessionRemoveFinishedTasksAction {
  static readonly type = '[Session] remove finished tasks';
}

export class SessionRemoveTaskAction {
  static readonly type = '[Session] remove task';

  constructor(readonly taskId: NonNullable<SessionTask['taskId']>) {}
}

export class SessionChangeSessionMessageAction {
  static readonly type = '[Session] change session message';

  constructor(
    readonly taskId: NonNullable<SessionTask['taskId']>,
    readonly sessionMessage: SessionMessage,
  ) {}
}
