import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { SessionState } from './session.state';

/**
 * Erre azert van szukseg, mert nem akarjuk a main-be provide-olni, viszont ez a state egy cmp-hez es nem egy route-hoz kotodik
 * igy a provideStates -et sem tudjuk hasznalni
 */
@NgModule({
  imports: [NgxsModule.forFeature([SessionState])],
  exports: [NgxsModule],
})
export class SessionStateModule {}
